import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

function ShopSuccess() {
  const navigate = useNavigate();
  const [isProcessed, setIsProcessed] = useState(false); // State to track if the key has been processed
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const key = searchParams.get("key");

    const processKey = async () => {
      if (key && !isProcessed) {
        try {
          const response = await fetch("/api/shop/create-donation-points", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ key }),
          });

          const data = await response.json();

          if (response.ok) {
            navigate("/shop");
          } else {
            console.error(data.message);
            navigate("/shop-not-succeeded");
          }
        } catch (error) {
          console.error("Error processing the key:", error);
          navigate("/shop-not-succeeded");
        } finally {
          setIsProcessed(true); // Ensure the key is marked as processed
        }
      } else {
        navigate("/shop-not-succeeded");
      }
    };

    processKey();
  }, [key, isProcessed, navigate]);

  return <div>Processing...</div>;
}

export default ShopSuccess;
