import getStripe from "./getStripe";
import { encrypt } from "./encryption";

export async function handleCheckout(
  accountName,
  quantity,
  price,
  successUrl,
  cancelUrl
) {
  const stripe = await getStripe();
  const key = encrypt(JSON.stringify({ accountName, quantity }));
  const { error } = await stripe.redirectToCheckout({
    lineItems: [
      {
        price,
        quantity: 1,
      },
    ],
    mode: "payment",
    successUrl: `${successUrl}?key=${key}`,
    cancelUrl,
  });
  console.warn(error.message);
}
