import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import "./Account.css";

function Account() {
  const [accountName, setAccountName] = useState("");
  const [verifier, setVerifier] = useState("");
  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [confirmNewEmail, setConfirmNewEmail] = useState("");
  const [passwordForEmailChange, setPasswordForEmailChange] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!accountName) {
      setAccountName(sessionStorage.getItem("account"));
      if (!sessionStorage.getItem("account")) {
        navigate("/login");
      }
    }

    if (!verifier) {
      setVerifier(sessionStorage.getItem("verifier"));
    }

    const getEmail = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          // "Cache-Control": "no-cache", // Disable cache,
          Accept: "application/json, text/javascript, */*; q=0.01",
        },
      };

      try {
        let response = await fetch(
          `/api/auth/email?accountName=${accountName}&verifier=${verifier}`,
          requestOptions
        );

        if (response.status == 404 || response.status == 304) {
          response = await fetch(
            `http://localhost:4000/auth/email?accountName=${accountName}&verifier=${verifier}`,
            requestOptions
          );
        }

        const data = await response.json();

        setEmail(data.email);
      } catch (error) {
        console.error("Error fetching online characters:", error);
      }
    };

    if (!email && accountName && verifier) {
      getEmail();
    }
  }, [accountName, verifier]);

  const handleLogout = () => {
    sessionStorage.removeItem("account");
    sessionStorage.removeItem("verifier");
    navigate("/");
  };

  async function handleChangeEmail(
    newEmail,
    confirmNewEmail,
    passwordForEmailChange
  ) {
    if (newEmail !== confirmNewEmail) {
      alert("New email and confirm new email do not match!");
      return;
    }

    try {
      const accountName = sessionStorage.getItem("account");
      const verifier = sessionStorage.getItem("verifier");

      let response = await fetch("/api/auth/email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          newEmail,
          accountName,
          password: passwordForEmailChange,
        }),
      });

      if (response.status === 404 || response.status == 304) {
        response = await fetch(`http://localhost:4000/auth/email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            newEmail,
            accountName,
            password: passwordForEmailChange,
          }),
        });
      }

      const data = await response.json();

      if (response.status === 200) {
        alert("Email successfully changed!");
      } else {
        alert(data.message || "Failed to change email.");
      }
    } catch (error) {
      console.error("Error changing email:", error);
      alert("An error occurred while changing email.");
    }
  }

  async function handleResetPassword() {
    try {
      const accountName = sessionStorage.getItem("account");

      let response = await fetch("/api/auth/reset-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accountName,
          newPassword,
        }),
      });

      if (response.status === 404 || response.status === 304) {
        response = await fetch("http://localhost:4000/auth/reset-password", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            accountName,
            newPassword,
          }),
        });
      }

      const data = await response.json();

      if (response.status === 200) {
        alert("Password reset successfully! Check your associated email.");
      } else {
        alert(data.message || "Failed to reset password.");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      alert("An error occurred while resetting password.");
    }
  }

  return (
    <div className="Account">
      <header className="sticky-header">
        <nav>
          <RouterLink to="/">Home</RouterLink>
          <RouterLink to="/">Connection</RouterLink>
          <RouterLink to="/">Info</RouterLink>
          <RouterLink to="/">Register</RouterLink>
          <RouterLink to="/shop">Shop</RouterLink>
          <RouterLink to="/account">Account</RouterLink>
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        </nav>
      </header>

      <div className="account-container">
        <h2>Hello {accountName || "Player"}</h2>

        <div className="email-center">
          <h2>Email Center</h2>

          <label htmlFor="current-email">Current email:</label>
          <input
            name="current-email"
            disabled
            value={email || "No associated email"}
          />

          <form
            onSubmit={async (e) => {
              e.preventDefault();
              handleChangeEmail(
                newEmail,
                confirmNewEmail,
                passwordForEmailChange
              );
            }}
          >
            <label htmlFor="new-email">New Email:</label>
            <input
              name="new-email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />

            <label htmlFor="confirm-new-email">Confirm New Email:</label>
            <input
              name="confirm-new-email"
              value={confirmNewEmail}
              onChange={(e) => setConfirmNewEmail(e.target.value)}
            />

            <label htmlFor="password-for-email">Current Password:</label>
            <input
              name="password-for-email"
              value={passwordForEmailChange}
              onChange={(e) => setPasswordForEmailChange(e.target.value)}
            />

            <button type="submit">Submit</button>
          </form>
        </div>

        <div className="password-center">
          <h2>Password Center</h2>

          <form
            onSubmit={async (e) => {
              e.preventDefault();
              handleResetPassword();
            }}
          >
            <label htmlFor="current-password">Current Password:</label>
            <input
              name="current-password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />

            <label htmlFor="new-password">New Password:</label>
            <input
              name="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />

            <label htmlFor="confirm-new-password">Confirm New Password:</label>
            <input
              name="confirm-new-password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Account;
