import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App.js";
import Login from "./Login.js";
import Shop from "./Shop.js";
import ShopNotSucceeded from "./ShopNotSucceeded.js";
import ShopSuccess from "./ShopSucceeded.js";
import Account from "./Account.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/shop",
    element: <Shop />,
  },
  {
    path: "/shop-not-succeeded",
    element: <ShopNotSucceeded />,
  },
  {
    path: "/shop-succeeded",
    element: <ShopSuccess />,
  },
  {
    path: "/account",
    element: <Account />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
