import React from "react";
import { Link as RouterLink } from "react-router-dom";
import "./ShopNotSucceeded.css";

function ShopNotSucceeded() {
  return (
    <div className="ShopNotSucceeded">
      <header className="sticky-header">
        <nav>
          <RouterLink to="/">Home</RouterLink>
          <RouterLink to="/">Connection</RouterLink>
          <RouterLink to="/">Info</RouterLink>
          <RouterLink to="/">Register</RouterLink>
          <RouterLink to="/shop">Shop</RouterLink>
          <RouterLink to="/account">Account</RouterLink>
        </nav>
      </header>

      <div className="content">
        <h1>Something Went Wrong</h1>
        <p>
          It looks like something went wrong with your purchase. You should not
          have been charged. If you do see a charge, please contact us on our{" "}
          <a
            href="https://discord.gg/2weK6BBwvG"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </a>{" "}
          and we can help resolve the issue.
        </p>
      </div>
    </div>
  );
}

export default ShopNotSucceeded;
