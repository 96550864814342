import CryptoJS from "crypto-js";

const secretKey = CryptoJS.enc.Utf8.parse(
  process.env.REACT_APP_SECRET_KEY || "your-secret-key".padEnd(32, "0")
); // Ensure the key is 32 bytes

// Helper function to convert WordArray to string
function wordArrayToString(wordArray) {
  return CryptoJS.enc.Utf8.stringify(wordArray);
}

export function encrypt(text) {
  const iv = CryptoJS.lib.WordArray.random(16);
  const key = secretKey;

  const encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const ivHex = iv.toString(CryptoJS.enc.Hex);
  const encryptedHex = encrypted.ciphertext.toString(CryptoJS.enc.Hex);

  return `${ivHex}:${encryptedHex}`;
}

export function decrypt(text) {
  const textParts = text.split(":");
  const iv = CryptoJS.enc.Hex.parse(textParts.shift());
  const encryptedText = CryptoJS.enc.Hex.parse(textParts.join(":"));
  const key = secretKey;

  const decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: encryptedText,
    },
    key,
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return wordArrayToString(decrypted);
}
