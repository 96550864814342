import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { handleCheckout } from "./lib/handleCheckout";
import "./Shop.css";
import env from "react-dotenv";

function Shop() {
  const [donationPoints, setDonationPoints] = useState(0);
  const [donorConfigs, setDonorConfigs] = useState([]);
  const [shopItems, setShopItems] = useState([]);
  const [characters, setCharacters] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [selectedCharacter, setSelectedCharacter] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data
    const fetchData = async () => {
      const accountName = sessionStorage.getItem("account");

      if (!accountName) {
        navigate("/login");
      }

      const pointsResponse = await fetch(
        `/api/shop/donation-points?accountName=${accountName}`
      );
      const pointsData = await pointsResponse.json();
      setDonationPoints(pointsData.points);
      setAccountName(accountName);

      const configsResponse = await fetch("/api/shop/donor-configs");
      const configsData = await configsResponse.json();
      setDonorConfigs(configsData);

      const itemsResponse = await fetch("/api/shop/shop-items");
      const itemsData = await itemsResponse.json();
      setShopItems(itemsData);

      const charactersResponse = await fetch("/api/auth/getCharacters", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: accountName,
          verifier: sessionStorage.getItem("verifier"),
        }),
      });
      const charactersData = await charactersResponse.json();

      if (
        !charactersData.characterNames ||
        !charactersData.characterNames.length
      ) {
        alert("Must have at least 1 character on main realm");
      }

      setCharacters(charactersData.characterNames);
    };

    fetchData();
  }, []);

  const handlePurchase = async (shopItemId, subject) => {
    try {
      const response = await fetch("/api/shop/execute-shop-command", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accountName,
          verifier: sessionStorage.getItem("verifier"),
          shopItemId,
          subject,
        }),
      });
      const data = await response.json();
      console.log(data);
      alert("Successfully purchased!");
    } catch (err) {
      alert("Error: " + err.message);
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem("account");
    sessionStorage.removeItem("verifier");
    navigate("/");
  };

  // Group shop items by category
  const categorizedItems = {
    armor: shopItems.filter((item) => item.category === "armor"),
    weapons: shopItems.filter((item) => item.category === "weapons"),
    misc: shopItems.filter((item) => item.category === "misc"),
  };

  return (
    <div className="Shop">
      <header className="sticky-header">
        <nav>
          <RouterLink to="/">Home</RouterLink>
          <RouterLink to="/">Connection</RouterLink>
          <RouterLink to="/">Info</RouterLink>
          <RouterLink to="/">Register</RouterLink>
          <RouterLink to="/shop">Shop</RouterLink>
          <RouterLink to="/account">Account</RouterLink>
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        </nav>
      </header>

      <div className="shop-container">
        <h1>Shop</h1>
        <div className="donation-points">
          <p>Current Donation Points: {donationPoints || "0"}</p>
        </div>

        <div className="donor-configs">
          {donorConfigs
            .sort((a, b) => a.price - b.price)
            .map((config) => (
              <button
                key={config.id}
                onClick={() =>
                  handleCheckout(
                    accountName,
                    config.quantity,
                    config.stripe_price_code,
                    "https://wrath-reborn.com/shop-succeeded",
                    "https://wrath-reborn.com/shop-not-succeeded"
                  )
                }
              >
                ${config.price} Donation - {config.quantity} points
              </button>
            ))}
        </div>

        <div className="shop-items">
          {["armor", "weapons", "misc"].map((category) => (
            <div key={category} class="item-category">
              <h2>{category.charAt(0).toUpperCase() + category.slice(1)}</h2>
              <div className="category-items">
                {categorizedItems[category].length > 0 ? (
                  categorizedItems[category].map((item, idx) => (
                    <div key={item.id} className="shop-item">
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                      <p>Price: {item.price} DP</p>
                      <p className="requires-online">
                        {item.requires_online ? "Requires Online" : null}
                      </p>
                      {item.image_url ? (
                        <img src={item.image_url} alt={item.title} />
                      ) : null}
                      {item.subject === "account" ? (
                        <input type="text" value={accountName} disabled />
                      ) : (
                        <select className={`${item.id}-item`}>
                          <option value="">-- SELECT --</option>
                          {characters && characters.length
                            ? characters.map((char, index) => (
                                <option key={index} value={char}>
                                  {char}
                                </option>
                              ))
                            : null}
                        </select>
                      )}
                      <button
                        onClick={() => {
                          const escapedItemId = CSS.escape(item.id); // Escape any special characters in item.id
                          const selectedValue = document.querySelector(
                            `select.${escapedItemId}-item`
                          ).value;
                          handlePurchase(
                            item.id,
                            item.subject === "account"
                              ? accountName
                              : selectedValue
                          );
                        }}
                        disabled={donationPoints < item.price}
                      >
                        Buy
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No items available in this category.</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Shop;
