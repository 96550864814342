import { loadStripe } from "@stripe/stripe-js";

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_live_51Pg6NvRxkqsshUNhDEyaWbArBMxRAint8apE0gNPeMbBgkjlmpH2LruNHrKHyNQKbn5B71LlOBDwqJgahPiEHTTb00pEVxXMCE"
    );
  }
  return stripePromise;
};

export default getStripe;
