import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import "./Login.css";
import env from "react-dotenv";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");

    try {
      let response = await fetch("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.status == 404 || response.status == 304) {
        response = await fetch("http://localhost:4000/auth/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password }),
        });
      }

      const data = await response.json();

      if (!response.ok) {
        setErrorMessage(data.message);
        return;
      }

      sessionStorage.setItem("account", username);
      sessionStorage.setItem("verifier", data.verifier);
      navigate("/");
    } catch (error) {
      setErrorMessage("An error occurred. Please try again later.");
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem("account");
    sessionStorage.removeItem("verifier");
    navigate("/");
  };

  return (
    <div className="App">
      <header className="sticky-header">
        <nav>
          <a href="/">Home</a>
          <a href="/">Connection</a>
          <a href="/">Info</a>
          <a href="/">Register</a>
          <a href="/login">Login</a>
          {/* <RouterLink to="/account">Account</RouterLink>
          <button className="logout-button" onClick={handleLogout}>
            Logout
          </button> */}
        </nav>
      </header>

      <section className="login-section">
        <h2>Login</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form onSubmit={handleSubmit}>
          <label htmlFor="username">Account name:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={handleUsernameChange}
            required
          />
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
          <button type="submit" className="submit-button">
            Login
          </button>
        </form>

        <button
          className="forgot-password-button"
          onClick={async () => {
            try {
              // First attempt: Send the request to the primary URL
              let response = await fetch("/api/auth/forgot-password", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ accountName: username }),
              });

              // If the response is 404, try the fallback local URL
              if (response.status === 404 || response.status !== 200) {
                response = await fetch(
                  "http://localhost:4000/auth/forgot-password",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ accountName: username }),
                  }
                );
              }

              // Process the response
              const data = await response.json();

              if (response.status === 200) {
                alert(
                  data.message ||
                    "Password reset successfully! Check your associated email."
                );
              } else {
                alert(data.message || "Failed to reset password.");
              }
            } catch (error) {
              console.error("Error resetting password:", error);
              alert("An error occurred while resetting the password.");
            }
          }}
        >
          Forgot password
        </button>
      </section>
    </div>
  );
}

export default Login;
